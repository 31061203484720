/* Modal Dialog */
.modal-dialog-xxl {
  max-width: 1600px;
}
/* Media Query for smaller screens */
@media (max-width: 575.98px) {
  .modal-dialog-xxl {
    max-width: 95%;
  }
}
.project-viewer .project-description p {
  text-indent: 2em;
  /* Adjust the value as needed */
}
