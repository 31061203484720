h1,
h2,
h3 {
  font-family: 'Muli', sans-serif;
}
.layer-cake {
  height: 100vh;
}
.vertical-center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.text-indent {
  text-indent: 25px;
}
@media (min-width: 991px) {
  .home {
    background-image: url('/src/assets/images/layer1_bg.jpg');
    background-attachment: fixed;
    background-size: cover;
  }
}
@media (max-width: 991px) {
  .home {
    background-color: #000;
  }
  .home:before {
    content: " ";
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('/src/assets/images/layer1_bg.jpg');
    opacity: 0.75;
    background-size: auto 100%;
    background-position: right bottom;
  }
}
.home .layer-about,
.home .layer-projects,
.home .layer-skills,
.home .layer-contact {
  background-color: white;
}
.home .layer-intro {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.home .layer-intro h1,
.home .layer-intro h2 {
  color: #fff;
}
.home .layer-intro h1 {
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
}
@media (min-width: 1750px) {
  .home .layer-intro h1 {
    font-size: 700%;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-intro h1 {
    font-size: 450%;
  }
}
.home .layer-intro h2 {
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
  font-weight: 300;
  opacity: 0.75;
  margin: 0;
}
@media (min-width: 1750px) {
  .home .layer-intro h2 {
    font-size: 250%;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-intro h2 {
    font-size: 150%;
  }
}
@media (max-width: 479px) {
  .home .layer-intro h2 {
    font-size: 120%;
  }
}
.home .layer-intro .portrait {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin: 0 auto;
  border: 6px solid #94f7da;
  max-width: 360px;
}
.home .layer-intro a {
  text-decoration: none;
}
.home .layer-intro .cta {
  width: 100%;
  border: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  background-color: #4aeebb;
  color: #fff;
  font-family: 'Muli', sans-serif;
  -webkit-transition: background-color .5s;
  -moz-transition: background-color .5s;
  -ms-transition: background-color .5s;
  -o-transition: background-color .5s;
  transition: background-color .5s;
}
.home .layer-intro .cta:hover {
  background-color: #42d4a6;
}
.home .layer-intro .cta.cta-blue {
  background-color: #70d8db;
}
.home .layer-intro .cta.cta-blue:hover {
  background-color: #1cc1c5;
}
@media (min-width: 991px) {
  .home .layer-intro .cta {
    border-radius: 50px;
    padding: 15px;
    font-size: 125%;
  }
}
@media (max-width: 991px) {
  .home .layer-intro .cta {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    padding: 10px;
  }
}
.home .layer-intro .typer {
  color: white;
  font-size: 98px;
  font-weight: 500;
  font-family: 'Inconsolata';
}
.home .layer-intro .typer .char-4,
.home .layer-intro .typer .char-5 {
  color: #4aeebb;
}
.home .layer-about {
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}
@media (min-width: 767px) {
  .home .layer-about {
    padding-top: 15px;
  }
}
.home .layer-about h1 {
  margin-top: 0;
  color: #4aeebb;
  font-weight: 300;
}
.home .layer-about h2 {
  color: #777;
  font-weight: 300;
}
@media (max-width: 767px) {
  .home .layer-about h2 {
    font-size: 160%;
  }
}
.home .layer-about .portrait {
  margin: 0 auto;
}
@media (min-width: 1199px) {
  .home .layer-about .row {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media (min-width: 767px) {
  .home .layer-about .row {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .home .layer-about .row {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.home .layer-about .tidbit p {
  font-family: 'Muli', open-sans;
  color: #777;
}
@media (max-width: 767px) {
  .home .layer-about .tidbit {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .home .layer-about .tidbit:not(:last-child):after {
    content: "";
    height: 1px;
    background-color: #777;
    width: 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.home .layer-about .quoted {
  position: relative;
}
.home .layer-about .quoted:before,
.home .layer-about .quoted:after {
  content: "";
  background-size: 100% 100%;
  position: absolute;
  opacity: 0.5;
  top: 0;
}
@media (min-width: 1750px) {
  .home .layer-about .quoted:before,
  .home .layer-about .quoted:after {
    height: 319px;
    width: 558px;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-about .quoted:before,
  .home .layer-about .quoted:after {
    height: 115px;
    width: 200px;
  }
}
@media (max-width: 1199px) {
  .home .layer-about .quoted:before,
  .home .layer-about .quoted:after {
    height: 57.5px;
    width: 100px;
  }
}
.home .layer-about .quoted:before {
  background-image: url('/src/assets/images/layer2_quote_left.png');
  right: 100%;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.home .layer-about .quoted:after {
  background-image: url('/src/assets/images/layer2_quote_right.png');
  left: 100%;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
.home .layer-about-divider,
.home .layer-projects-divider,
.home .layer-skills-divider {
  padding-top: 15px;
  position: relative;
}
@media (min-width: 1750px) {
  .home .layer-about-divider,
  .home .layer-projects-divider,
  .home .layer-skills-divider {
    height: 192px;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-about-divider,
  .home .layer-projects-divider,
  .home .layer-skills-divider {
    height: 100px;
  }
}
@media (max-width: 1199px) {
  .home .layer-about-divider,
  .home .layer-projects-divider,
  .home .layer-skills-divider {
    height: 60px;
  }
}
.home .layer-projects {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}
@media (min-width: 767px) {
  .home .layer-projects {
    padding-top: 15px;
  }
}
@media (min-width: 1199px) {
  .home .layer-projects .row {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media (min-width: 767px) {
  .home .layer-projects .row {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .home .layer-projects .row {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.home .layer-projects h3 {
  color: #777;
  font-weight: 300;
}
@media (max-width: 767px) {
  .home .layer-projects h3 {
    font-size: 125%;
  }
}
.home .layer-projects h1 {
  margin-top: 0;
  color: #70d8db;
  font-weight: 300;
}
@media (min-width: 767px) {
  .home .layer-projects h1 {
    margin-bottom: 25px;
  }
}
.home .layer-projects .imac {
  max-width: 200px;
  margin-left: auto;
}
.home .layer-projects .thumbnail-col {
  padding: 0 10px;
  display: inline-block;
}
@media (min-width: 1199px) {
  .home .layer-projects .thumbnail-col {
    width: 25%;
    padding: 0 15px;
  }
}
@media (min-width: 767px) AND (max-width: 1199px) {
  .home .layer-projects .thumbnail-col {
    width: 25%;
  }
}
@media (max-width: 767px) AND (min-width: 479px) {
  .home .layer-projects .thumbnail-col {
    width: 33.3333%;
  }
}
@media (max-width: 479px) {
  .home .layer-projects .thumbnail-col {
    width: 50%;
  }
}
.home .layer-projects .thumbnail {
  padding: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background: #000000;
  border: 0;
}
.home .layer-projects .thumbnail:hover img {
  opacity: 0.35;
}
.home .layer-projects .thumbnail:hover .caption {
  opacity: 1;
  color: white;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -moz-transform: translateX(-50%) translateY(-50%) scale(1);
  -ms-transform: translateX(-50%) translateY(-50%) scale(1);
  -o-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.home .layer-projects .thumbnail .caption {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(.8);
  -moz-transform: translateX(-50%) translateY(-50%) scale(.8);
  -ms-transform: translateX(-50%) translateY(-50%) scale(.8);
  -o-transform: translateX(-50%) translateY(-50%) scale(.8);
  transform: translateX(-50%) translateY(-50%) scale(.8);
  opacity: 0;
  padding: 0;
  text-align: center;
  -webkit-transition: transform .25s;
  -moz-transition: transform .25s;
  -ms-transition: transform .25s;
  -o-transition: transform .25s;
  transition: transform .25s;
}
.home .layer-projects .thumbnail .service-icon {
  width: 35px;
  height: 35px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #4aeebb;
  color: #2b8061;
  display: inline-block;
  position: relative;
  margin: 0 5px;
}
.home .layer-projects .thumbnail .service-icon i {
  font-size: 17.5px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.home .layer-projects .thumbnail img {
  min-width: 100%;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}
.home .layer-projects .thumbnail h4 {
  text-align: center;
  font-family: 'Muli', sans-serif;
  margin: 0 0 15px 0;
}
.home .layer-projects .thumbnail small {
  margin-bottom: 10px;
  display: block;
  color: #aaa;
}
.home .layer-projects .thumbnail p {
  padding: 10px 15px 5px;
  text-align: center;
  color: #aaa;
}
.home .layer-projects .briefcase {
  position: relative;
  z-index: -1;
}
.home .layer-projects .briefcase:before {
  background-image: url('/src/assets/images/layer3_briefcase.png');
  left: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  background-size: 100% 100%;
  position: absolute;
  opacity: 0.5;
}
@media (min-width: 1750px) {
  .home .layer-projects .briefcase:before {
    width: 512px;
    height: 434px;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-projects .briefcase:before {
    width: 400px;
    height: 339px;
  }
}
@media (max-width: 1199px) {
  .home .layer-projects .briefcase:before {
    width: 300px;
    height: 255px;
  }
}
@media (max-width: 991px) {
  .home .layer-projects .briefcase:before {
    display: none;
  }
}
.home .layer-skills {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  overflow: hidden;
}
@media (min-width: 767px) {
  .home .layer-skills {
    padding-top: 15px;
  }
}
@media (min-width: 1199px) {
  .home .layer-skills .row {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media (min-width: 767px) {
  .home .layer-skills .row {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .home .layer-skills .row {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.home .layer-skills h1 {
  margin-top: 0;
  color: #4aeebb;
  font-weight: 300;
}
.home .layer-skills h3 {
  color: #777;
  font-weight: 300;
}
@media (max-width: 767px) {
  .home .layer-skills h3 {
    font-size: 125%;
  }
}
.home .layer-skills h4 {
  color: #555;
  font-weight: 300;
}
@media (max-width: 767px) {
  .home .layer-skills h4 {
    font-size: 125%;
  }
}
.home .layer-skills .hammer {
  position: relative;
}
.home .layer-skills .hammer:before {
  background-image: url('/src/assets/images/layer4_hammer.png');
  left: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  background-size: 100% 100%;
  position: absolute;
  opacity: 0.5;
}
@media (min-width: 1750px) {
  .home .layer-skills .hammer:before {
    height: 417px;
    width: 444px;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-skills .hammer:before {
    height: 300px;
    width: 319px;
  }
}
@media (max-width: 1199px) {
  .home .layer-skills .hammer:before {
    height: 150px;
    width: 159px;
  }
}
@media (max-width: 991px) {
  .home .layer-skills .hammer:before {
    display: none;
  }
}
.home .layer-skills .skill-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.home .layer-skills .skill-row .skill {
  float: none;
}
@media (max-width: 767px) {
  .home .layer-skills .skill-row .skill {
    text-align: center;
  }
  .home .layer-skills .skill-row .skill img {
    margin-bottom: 10px;
    max-width: 65px;
    max-height: 65px;
  }
}
@media (max-width: 479px) {
  .home .layer-skills .skill-row .skill img {
    margin-bottom: 10px;
    max-width: 65px;
    max-height: 65px;
  }
}
.home .layer-skills .skill-row:not(:last-child) {
  border-bottom: 1px solid #aaa;
}
@media (max-width: 479px) {
  .home .layer-skills .skill-row {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (min-width: 767px) {
  .home .layer-skills .skill {
    margin-bottom: 35px;
    text-align: center;
  }
}
.home .layer-skills .skill img {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 767px) {
  .home .layer-skills .skill img {
    max-width: 150px;
    max-height: 50px;
  }
}
@media (max-width: 767px) {
  .home .layer-skills .skill h3 {
    margin-top: 0;
  }
}
.home .layer-skills .skill p {
  font-family: 'Muli', sans-serif;
}
.home .layer-contact {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}
@media (min-width: 767px) {
  .home .layer-contact {
    padding-top: 15px;
  }
}
@media (min-width: 1199px) {
  .home .layer-contact .row {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
@media (min-width: 767px) {
  .home .layer-contact .row {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .home .layer-contact .row {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.home .layer-contact h1 {
  margin-top: 0;
  color: #4aeebb;
  font-weight: 300;
}
.home .layer-contact h3 {
  color: #777;
  font-weight: 300;
}
@media (max-width: 767px) {
  .home .layer-contact h3 {
    font-size: 125%;
  }
}
.home .layer-contact .contact {
  position: relative;
  padding-left: 75px;
  height: 66px;
  margin-bottom: 15px;
  max-width: 300px;
}
.home .layer-contact .contact img {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home .layer-contact .contact p {
  line-height: 66px;
  font-family: 'Muli', sans-serif;
  font-size: 110%;
  color: #777;
}
.home .layer-contact .contact a {
  text-decoration: none;
}
@media (max-width: 991px) {
  .home .layer-contact .contact {
    margin-left: auto;
    margin-right: auto;
  }
}
.home .layer-contact .shake {
  position: relative;
}
.home .layer-contact .shake:before {
  background-image: url('/src/assets/images/layer5_fade_shake.png');
  left: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.5;
  content: "";
  background-size: 100% 100%;
  position: absolute;
}
@media (min-width: 1750px) {
  .home .layer-contact .shake:before {
    height: 199px;
    width: 458px;
  }
}
@media (min-width: 1199px) AND (max-width: 1750px) {
  .home .layer-contact .shake:before {
    height: 130px;
    width: 300px;
  }
}
@media (max-width: 1199px) {
  .home .layer-contact .shake:before {
    height: 87px;
    width: 200px;
  }
}
@media (max-width: 991px) {
  .home .layer-contact .shake:before {
    display: none;
  }
}
.home footer {
  position: relative;
  z-index: 1;
}
@media (min-width: 991px) {
  .home footer {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .home footer {
    padding: 10px;
  }
}
.home footer h1,
.home footer h2,
.home footer h3,
.home footer h4,
.home footer h5 {
  color: #fff;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.portfolio-viewer {
  text-align: center;
}
@media (min-width: 767px) {
  .portfolio-viewer .modal-dialog {
    transform: initial !important;
  }
}
.portfolio-viewer .container-fluid {
  padding: 0;
}
.portfolio-viewer .modal-body {
  padding: 0;
}
.portfolio-viewer .close {
  padding: 10px 10px;
  position: absolute;
  top: 0;
  right: 15px;
  color: #fff;
  text-shadow: 0 1px 0 #000;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin: 5px;
}
.portfolio-viewer .close:hover {
  opacity: 1;
}
.portfolio-viewer p {
  font-family: 'Muli', sans-serif;
}
.portfolio-viewer .modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: 0;
}
.portfolio-viewer .col-img {
  overflow: hidden;
}
@media (max-width: 767px) {
  .portfolio-viewer .col-img {
    padding-bottom: 15px;
  }
}
.portfolio-viewer .col-img .wrapper {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background-color: #eee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.portfolio-viewer .col-img .portfolio-item {
  position: relative;
}
.portfolio-viewer .col-img .portfolio-item .arrow {
  position: absolute;
  top: 0;
  height: 100%;
  color: white;
  opacity: 0.35;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
  text-shadow: 1px 1px 8px black;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .portfolio-viewer .col-img .portfolio-item .arrow {
    font-size: 650%;
  }
}
@media (min-width: 767px) AND (max-width: 1200px) {
  .portfolio-viewer .col-img .portfolio-item .arrow {
    font-size: 400%;
  }
}
@media (max-width: 767px) {
  .portfolio-viewer .col-img .portfolio-item .arrow {
    font-size: 250%;
  }
}
.portfolio-viewer .col-img .portfolio-item .arrow:hover {
  opacity: 0.9;
}
.portfolio-viewer .col-img .portfolio-item .arrow.arrow-left {
  left: 5px;
}
.portfolio-viewer .col-img .portfolio-item .arrow.arrow-right {
  right: 5px;
}
@media (min-width: 1200px) {
  .portfolio-viewer .col-img .portfolio-item .arrow i {
    position: relative;
    top: 250px;
  }
}
@media (min-width: 767px) AND (max-width: 1200px) {
  .portfolio-viewer .col-img .portfolio-item .arrow i {
    position: relative;
    top: 150px;
  }
}
@media (max-width: 767px) {
  .portfolio-viewer .col-img .portfolio-item .arrow i {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.portfolio-viewer .col-img img {
  margin: 0 auto;
  -webkit-transition: height 2s;
  -moz-transition: height 2s;
  -ms-transition: height 2s;
  -o-transition: height 2s;
  transition: height 2s;
}
.portfolio-viewer .col-img .title {
  padding: 25px;
  border-bottom: 1px solid #999;
}
.portfolio-viewer .col-img .title h1,
.portfolio-viewer .col-img .title h2,
.portfolio-viewer .col-img .title h3 {
  margin: 0;
  color: #333;
}
@media (max-width: 767px) {
  .portfolio-viewer .col-img .title h1,
  .portfolio-viewer .col-img .title h2,
  .portfolio-viewer .col-img .title h3 {
    font-size: 175%;
  }
}
.portfolio-viewer .col-img .title h4,
.portfolio-viewer .col-img .title h5 {
  color: #777;
  position: relative;
  display: inline-block;
  z-index: 1;
  background-color: #eee;
}
.portfolio-viewer .col-img .title h4:before,
.portfolio-viewer .col-img .title h5:before {
  content: " ";
  z-index: -1;
  position: absolute;
  left: -15px;
  right: -15px;
  height: 100%;
  background-color: #eee;
}
.portfolio-viewer .col-img .title h4:after,
.portfolio-viewer .col-img .title h5:after {
  content: " ";
  z-index: -2;
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  max-width: 250px;
  width: 250%;
  background-color: #888;
}
@media (min-width: 767px) {
  .portfolio-viewer .col-img {
    padding-left: 0;
  }
}
.portfolio-viewer .col-text {
  overflow: hidden;
}
.portfolio-viewer .col-text .wrapper {
  padding: 15px;
  background-color: #eee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
}
@media (min-width: 767px) {
  .portfolio-viewer .col-text .wrapper {
    min-height: 150px;
  }
}
.portfolio-viewer .col-text.is_stuck {
  margin-top: 15px;
}
.portfolio-viewer .col-text .title {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: #666;
  font-weight: bold;
}
.portfolio-viewer .col-text .title:before {
  content: " ";
  z-index: -1;
  position: absolute;
  left: -15px;
  right: -15px;
  height: 100%;
  background-color: #eee;
}
.portfolio-viewer .col-text .title:after {
  content: " ";
  z-index: -2;
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  max-width: 250px;
  width: 225px;
  background-color: #888;
}
.portfolio-viewer .col-text .description {
  padding-top: 10px;
  font-size: 110%;
  color: #222;
}
.portfolio-viewer .col-text .samples .sample {
  width: 60px;
  height: 60px;
  margin: 5px;
  display: inline-block;
  overflow: hidden;
  border: 3px solid #888;
  cursor: pointer;
}
.portfolio-viewer .col-text .samples .sample.selected {
  border-color: #444;
}
.portfolio-viewer .col-text .samples .sample.selected img {
  opacity: 1;
}
.portfolio-viewer .col-text .samples .sample img {
  opacity: 0.7;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}
.portfolio-viewer .col-text .samples .sample img:hover {
  opacity: 1;
}
@media (min-width: 767px) {
  .portfolio-viewer .col-text {
    padding-right: 0px;
  }
}
.portfolio-viewer .modal-dialog {
  width: auto;
  max-width: 95%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
