.thumbnail {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  tab-index: 0;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}
.thumbnail:hover {
  border: 2px solid rgba(0, 0, 0, 0.5);
}
.thumbnail img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
