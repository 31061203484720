.carousel .carousel-item {
  text-align: center;
}
.carousel:hover .carousel-control-next {
  background: #ffffff;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
}
.carousel:hover .carousel-control-next:hover {
  background: #ffffff;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.carousel:hover .carousel-control-prev {
  background: #ffffff;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
}
.carousel:hover .carousel-control-prev:hover {
  background: #ffffff;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}
